
  import { Component } from 'vue-property-decorator'
  import Cell from './cell'

@Component
  export default class AlertAppraisal extends Cell {
  addAlert = false

  alert = {
    background: 'white',
    color: 'green',
    icon: 'mdi-camera',
    tooltip: 'Fotografías adjuntas',
    value: 0,
  }

  async mounted () {
    const idProcess = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'appraisal' } },
    }))?.[0]?.id

    const photos = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: this.item.id } }, { parameter: { process: { id: { _eq: idProcess } } } }, { parameter: { file_type: { name: { _eq: 'photo' } } } }] },
      force: true,
    })

    this.addAlert = photos.length
  }

  get alerts () {
    const { isSupervisor, isAppraiser, isStaff } = this
    let alertComments = []

    if (isStaff) {
      alertComments = this.item.alertCommentStaff
    } else if (isSupervisor || isAppraiser) {
      alertComments = this.item.alertComment
    }

    return [
      ...alertComments,
      ...this.item.appraisalAlert,
      this.addAlert ? this.alert : null,
    ].filter(Boolean)
  }

  get isSupervisor () {
    return this.$route.fullPath.includes('supervisor')
  }

  get isAppraiser () {
    return this.$route.fullPath.includes('appraiser')
  }

  get isStaff () {
    return this.$route.fullPath.includes('staff')
  }
  }
